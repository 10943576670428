import { BinaryOperator, Filter } from "@cubejs-client/core";
import { Segment, MappingRuleResult } from "Api/mappingConfigurator";
import { chain, Dictionary, groupBy } from "lodash";
import { ListValue } from "Pages/MappingConfigurator/AntDesignComponents/MultiSelectWidget";
import { MappedSegmentTypes } from "Pages/MappingConfigurator/MappingConfiguratorEditorPage";
import {
    Utils as QbUtils,
    ImmutableTree,
    Config,
} from "react-awesome-query-builder";
import { Maybe } from "types";

export const validateMappingObject = (params: {
    tree: ImmutableTree;
    config: any;
}): boolean => {
    const { tree, config } = params;

    return (
        QbUtils.isValidTree(tree) &&
        Boolean(QbUtils.jsonLogicFormat(tree, config).logic)
    );
};

const parseMongodbTree = (mongodbTree: any): MappingRuleResult[] =>
    Object.values(
        Object.entries(mongodbTree).reduce(
            (result, [integrationSourceWithSegment, segmentDetails]: any) => {
                const [integrationSource, segment] =
                    integrationSourceWithSegment.split("_");
                if (!segmentDetails) {
                    return result;
                }
                if (!result[integrationSource]) {
                    result[integrationSource] = {
                        [`${segment}Ids`]: segmentDetails.map((segmentDetail) => {
                            const [_, segmentId, segmentName] =
                                segmentDetail.split("_");
                            return [segmentId, segmentName].join("_");
                        }),
                        integrationSource,
                    };
                } else {
                    result[integrationSource][`${segment}Ids`] = segmentDetails.map(
                        (segmentDetail: string) => {
                            const [_, segmentId, segmentName] =
                                segmentDetail.split("_");
                            return [segmentId, segmentName].join("_");
                        }
                    );
                }

                return result;
            },
            {}
        )
    );

export const parseTreeLogic = (
    currentRuleTree: ImmutableTree,
    config: Config
): Maybe<MappingRuleResult[]> => {
    const mongodbTree = QbUtils.mongodbFormat(currentRuleTree, config);
    if (!mongodbTree) return;

    return mongodbTree["$or"]
        ? mongodbTree["$or"].flatMap(parseMongodbTree)
        : mongodbTree["$and"]
        ? mongodbTree["$and"].flatMap(parseMongodbTree)
        : parseMongodbTree(mongodbTree);
};

export const applyMappingProperties = (
    currentSelectOption: { title: string; value: string },
    integrationSource: string,
    knownMappedRules?: MappedSegmentTypes | undefined,
    selectedSegment?: "class" | "area" | "venue"
): ListValue => {
    const timesheetIntegration = ["tanda", "deputy", "keypay"];

    if (selectedSegment === undefined || knownMappedRules === undefined) {
        return currentSelectOption;
    }

    const checkAgainst = `${currentSelectOption.value.split("_")[1]}_${
        currentSelectOption.value.split("_")[2]
    }`;

    const countOccurrences = (arr: string[], val: string) =>
        arr.reduce((count, item) => (item === val ? count + 1 : count), 0);

    if (selectedSegment === "class") {
        const classMatcher = `${integrationSource}_class`;
        const areaMatcher = `${integrationSource}_area`;

        const knownMappedClassSegments =
            knownMappedRules["class"][classMatcher] ?? [];
        const knownMappedAreaSegments = knownMappedRules["area"][areaMatcher] ?? [];

        if (knownMappedClassSegments.includes(checkAgainst)) {
            if (timesheetIntegration.includes(integrationSource)) {
                return {
                    warning: true,
                    ...currentSelectOption,
                    count: countOccurrences(knownMappedClassSegments, checkAgainst),
                };
            }
            return { disabled: true, ...currentSelectOption };
        }

        if (knownMappedAreaSegments.includes(checkAgainst)) {
            if (timesheetIntegration.includes(integrationSource)) {
                return {
                    warning: true,
                    ...currentSelectOption,
                    count: countOccurrences(knownMappedAreaSegments, checkAgainst),
                };
            }
            return { disabled: true, ...currentSelectOption };
        }
    } else {
        const matcher = `${integrationSource}_${selectedSegment}`;
        const knownMappedSegments = knownMappedRules[selectedSegment][matcher] ?? [];
        if (knownMappedSegments.includes(checkAgainst)) {
            return { disabled: true, ...currentSelectOption };
        }
    }

    // Default case
    return currentSelectOption;
};

export const getFieldSettings = ({
    segments,
    venueNameById,
    knownMappedRules,
    selectedSegment,
}: {
    segments: Segment[];
    venueNameById: Dictionary<string>;
    knownMappedRules?: MappedSegmentTypes;
    selectedSegment?: "class" | "area" | "venue";
}) =>
    chain(segments)
        .groupBy("integrationName")
        .flatMap((segmentsByIntegrationSource, integrationSource) => {
            const segmentsBySegmentType = groupBy(
                segmentsByIntegrationSource,
                "segmentType"
            );

            return Object.keys(segmentsBySegmentType).map((segmentType) => {
                const listValues: {
                    title: string;
                    value: any;
                    disabled?: boolean;
                }[] = segmentsBySegmentType[segmentType].map(
                    ({
                        segmentName,
                        segmentId,
                        integrationName,
                        parentSegmentId,
                    }) => {
                        return applyMappingProperties(
                            {
                                title:
                                    parentSegmentId != null && segmentType === "area"
                                        ? `(${
                                              venueNameById[
                                                  integrationName +
                                                      "_" +
                                                      parentSegmentId
                                              ]
                                          }) ${segmentName}`
                                        : segmentName,
                                // Note: segmentId is usually null and is used as a workaround to prevent the duplicate values (This is a last line of defence against collisions)
                                // TODO: Add test for this collision case
                                // value: `${integrationSource}_${null}_${segmentName}`,
                                value: `${integrationSource}_${segmentId}_${segmentName}`,
                                // disabled: true
                            },
                            integrationSource,
                            knownMappedRules,
                            selectedSegment
                        );
                    }
                );
                return {
                    fieldName: `${integrationSource}_${segmentType}`,
                    values: {
                        label: String.capitalizeFirstLetterOfEveryWordInASentence(
                            `${integrationSource} ${segmentType}`
                        ),
                        type: "multiselect",
                        fieldSettings: {
                            listValues: listValues
                                .sort((leftItem, rightItem) =>
                                    leftItem.title.localeCompare(rightItem.title)
                                )
                                .sort((leftItem, rightItem) => {
                                    return leftItem?.disabled
                                        ? 1
                                        : rightItem?.disabled
                                        ? -1
                                        : 0;
                                }),
                        },
                    },
                };
            });
        })
        .keyBy("fieldName")
        .mapValues("values")
        .value();

type SingleItemWithClassIdsResult = {
    multiple: boolean;
    found: string[] | undefined;
};

export const buildCubeFilters = ({
    mappingResults,
    cubeName,
    operator,
    serviceIdsByIntegrationName,
}: {
    mappingResults: Partial<MappingRuleResult>[];
    cubeName: string;
    serviceIdsByIntegrationName?: Dictionary<number[]>;
    operator: BinaryOperator;
}): {
    and: Filter[];
}[] => {
    const singleItemWithClassIdsResult =
        mappingResults.reduce<SingleItemWithClassIdsResult>(
            (result, mappingResult) => {
                if (!mappingResult.classIds) {
                    return result;
                }

                if (result.found === undefined) {
                    return { ...result, found: mappingResult.classIds };
                }

                return { ...result, multiple: true };
            },
            { multiple: false, found: undefined }
        );

    const hasExactlyOneItemWithClassIds = !singleItemWithClassIdsResult.multiple;
    const singleItemWithClassIds = singleItemWithClassIdsResult.found;

    // So if there is a class we need to also include it in the venue Regardless of the operator
    return mappingResults.map(
        ({ areaIds, classIds, venueIds, integrationSource }) => {
            const filters: Filter[] = [];

            if (serviceIdsByIntegrationName && integrationSource) {
                filters.push({
                    member: `${cubeName}.serviceId`,
                    values: serviceIdsByIntegrationName[integrationSource].map(
                        String
                    ),
                    operator: "equals",
                });
            }
            if (areaIds) {
                filters.push({
                    member: `${cubeName}.foreignAreaID`,
                    values: areaIds.map((area) => {
                        const [areaId] = area.split("_");
                        return areaId;
                    }),
                    operator: operator,
                });
            }

            if (venueIds) {
                filters.push({
                    member: `${cubeName}.foreignVenueID`,
                    values: venueIds.filter(Boolean).map((venue) => {
                        const [venueId] = venue.split("_");
                        return venueId;
                    }),
                    operator: operator,
                });
            }

            if (classIds) {
                filters.push({
                    member: `${cubeName}.foreignClass`,
                    values: classIds.map((classInfo) => {
                        const [_, className] = classInfo.split("_");
                        return className;
                    }),
                    operator: operator,
                });
            }

            // TODO Requires more testing
            if (
                !classIds &&
                hasExactlyOneItemWithClassIds &&
                singleItemWithClassIds
            ) {
                filters.push({
                    member: `${cubeName}.foreignClass`,
                    values: singleItemWithClassIds.map((classInfo) => {
                        const [_, className] = classInfo.split("_");
                        return className;
                    }),
                    operator: operator,
                });
            }

            return {
                and: filters,
            };
        }
    );
};
