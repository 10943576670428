import React, { createContext, ReactElement, ReactNode, useReducer } from "react";
import Reducer from "./reducer";
import { Area, Class, GroupData, Venue } from "../Api/backend";
import { Aggregate } from "../Components/AggregateFilter";
import moment from "moment";
import { WeekPeriod } from "../Components/ForecastingDashboardDesktop/WeekPeriodSelectorManager";
import { Action } from "./actions";
import { SalesType } from "../Api/Cube/types";
import { ComparisonEnums } from "Utils/constants";

export interface SelectedDates {
    fromDate: string;
    toDate: string;
    isPresetDateRange?: boolean;
    presetDateRangeLabel?: string;
}

export interface Filters {
    selectedVenues: Venue[];
    selectedAreas: Area[];
    selectedClasses: Class[];
    selectedDates: SelectedDates;
    selectedDays: string[];
    selectedHours: number[];
    selectedAggregate: Aggregate | null;
    selectedComparison: ComparisonEnums | null;
    datasetName?: string;
    selectedViewId?: number;
    mode: SalesType;
}

export type AnalysisVariance =
    | "AverageAggregate"
    | "MedianAggregate"
    | "DateComparison"
    | "ForecastComparison"
    | "SameDayLastWeek"
    | "PreviousPeriod"
    | "SamePeriodLastYear"
    | "NoAnalysis";

export interface ForecastMetadata {
    createdBy: string;
    createdDate: string;
    modifedBy: string;
    modifiedDate: string;
}

export interface BaselineConfiguration {
    maxPercentile?: number;
    avgSmoothPreceding?: number;
    avgSmoothFollowing?: number;
}

export interface ForecastConfiguration {
    venue: string;
    weekPeriod: string;
}

export type ValueType = "Flat" | "Percentage";

export interface ForecastDriver {
    id: string;
    name: string;
    timePeriod: {
        fromDate: string;
        toDate: string;
    };
    areas: string[];
    classes: string[];
    value: number;
    valueType: ValueType;
    spread: "Even" | "Proportional";
    override: boolean;
}

export type Baseline = WeekPeriod[];

export interface ForecastPage {
    toggleCreateBaseline?: boolean;
    toggleApplyDriver?: boolean;
}

export interface Forecast {
    forecastId: string;
    forecastLabel: string;
    groupId: string;
    datasetName: string;
    configuration: ForecastConfiguration;
    baselineConfiguration?: BaselineConfiguration;
    drivers: ForecastDriver[];
    baseline: Baseline;
    message?: string;
}

export interface State {
    filters: Filters;

    // This is the only thing that should stay (For a moment)
    groupData?: GroupData;
    forecastPage?: ForecastPage;
}

export const initialState: State = {
    filters: {
        selectedVenues: [],
        selectedAreas: [],
        selectedClasses: [],
        selectedDates: {
            fromDate: moment().format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
        },
        selectedDays: [],
        selectedHours: [],
        selectedAggregate: null,
        selectedComparison: ComparisonEnums.SAME_DAY_LAST_WEEK,
        mode: "actual",
    },
    forecastPage: {
        toggleApplyDriver: false,
        toggleCreateBaseline: false,
    },
};

interface Props {
    children: ReactNode;
}

// TODO replace with actual typing
export const Context = createContext<[State, React.Dispatch<Action>]>([
    initialState,
    () => undefined,
]);

// TODO replace with actual typing
const Store = ({ children }: Props): ReactElement => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state as State, dispatch]}>
            {children}
        </Context.Provider>
    );
};
export default Store;
