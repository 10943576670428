import React, { ReactElement, useEffect, useState } from "react";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { Skeleton, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import { Forecast } from "../../State/store";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import { ApexChartsSeries } from "../../Api/Cube/types";
import { WRANGLR_DARK_BLUE, WRANGLR_LIGHT_BLUE } from "../../Utils/constants";
import { FilteredSegments } from ".";
import {
    DEFAULT_GRANULARITY_IN_MINUTES,
    MINUTES_IN_HOURS,
} from "../../Utils/date-utils";
import { currencyFormatter } from "Utils/utils";

export interface BaselineRawRow {
    mapped_area: string;
    mapped_class: string;
    relative_timestamp: string;
    base: number;
    forecast?: number;
}

export interface BaselineMappedRow {
    x: string;
    base: number;
    forecast: number;
}

interface BaselineSeries {
    series: ApexChartsSeries;
}

const getOptions = (xAxisData: string[]): ChartOptions => {
    return {
        options: {
            chart: {
                id: "baselineChart",
                toolbar: {
                    tools: {
                        download: false,
                        pan: false,
                        zoomin: false,
                        zoomout: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                tickAmount: 7,
                categories: xAxisData,
                labels: {
                    datetimeUTC: false,
                    formatter: (val) => moment(val).format("ddd HH:mm (D/M)"),
                },
            },
            colors: [WRANGLR_LIGHT_BLUE, WRANGLR_DARK_BLUE],
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.91,
                    opacityTo: 0.1,
                },
            },
            yaxis: {
                tickAmount: 2,
                min: 0,
                labels: {
                    formatter: (num) => {
                        return currencyFormatter(num);
                    },
                },
                forceNiceScale: true,
            },
            grid: {
                show: true,
            },
            stroke: {
                width: 1,
                curve: "straight",
            },
        },
    };
};

export const groupDataByField =
    (resultArr: BaselineMappedRow[], targetField: string): any =>
    (res: {}, value: BaselineRawRow) => {
        const { base, forecast } = value;
        const targetMapping = value[targetField];

        if (!res[targetMapping]) {
            res[targetMapping] = {
                x: targetMapping,
                base: 0,
                forecast: 0,
            };
            resultArr.push(res[targetMapping]);
        }
        res[targetMapping].base += base;
        if (forecast) {
            res[targetMapping].forecast += forecast;
        }
        return res;
    };

interface ChartOptions {
    options?: ApexOptions;
    brushChartOptions?: ApexOptions;
}

interface Props {
    baselineData: BaselineRawRow[];
    loading: boolean;
    mode: "baseline" | "drivers";
    filteredSegments: FilteredSegments;
}

const BaselineChart = ({
    baselineData,
    loading,
    mode,
    filteredSegments,
}: Props): ReactElement => {
    const [options, setOptions] = useState<ChartOptions>({});
    const [series, setSeries] = useState<BaselineSeries>({ series: [] });

    useEffect(() => {
        if (!loading && baselineData.length) {
            const result: BaselineMappedRow[] = [];
            baselineData.reduce(groupDataByField(result, "relative_timestamp"), {});

            // Filter out first 6 hours of the next Monday.
            const removedDuplicatedMondayResult = filteredSegments.days.includes(
                "Monday"
            )
                ? result.slice(
                      0,
                      -((MINUTES_IN_HOURS * 6) / DEFAULT_GRANULARITY_IN_MINUTES)
                  )
                : result;

            setOptions(
                getOptions(removedDuplicatedMondayResult.map((row) => row.x))
            );
            const s = {
                series: [
                    {
                        name: "Baseline",
                        data: removedDuplicatedMondayResult.map((r) => r.base),
                    },
                ],
            };
            if (mode === "drivers") {
                s.series.push({
                    name: "Forecast",
                    data: removedDuplicatedMondayResult.map((r) => r.forecast),
                });
            }
            setSeries(s);
        }
    }, [loading, mode, baselineData]);

    if (loading) {
        return (
            <>
                <Skeleton active={true} />
                <Skeleton active={true} />
            </>
        );
    }

    return (
        <>
            {baselineData.length > 0 ? (
                <>
                    <ReactApexChart
                        options={options.options ? options.options : {}}
                        series={series.series ? series.series : []}
                        type={"area"}
                        width="100%"
                        height={200}
                    />
                </>
            ) : (
                <>
                    <Typography.Text type={"secondary"}>
                        Missing forecast data
                    </Typography.Text>
                </>
            )}
        </>
    );
};

export default BaselineChart;
