import React, { Dispatch, ReactElement } from "react";
import { WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import { Button, Col, Row, Typography } from "antd";
import { DriverModalButton } from "./DriverModalButton";
import DriversTable from "./DriversTable";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { ApiOutlined } from "@ant-design/icons";
import { Context, State } from "../../State/store";
import { Action, ToggleApplyDriverForecasts } from "../../State/actions";

export default function DriversCard(): ReactElement {
    const { forecast } = useCurrentForecast();
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const { toggleCreateBaseline, toggleApplyDriver } = state.forecastPage!;
    const onClick = () => {
        const action: ToggleApplyDriverForecasts = {
            type: "TOGGLE_APPLY_DRIVER_FORECASTS",
        };
        dispatch(action);
    };

    return (
        <>
            <WranglrDarkBlueTitle level={4}>
                Add drivers that may affect sales to adjust your forecast
            </WranglrDarkBlueTitle>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    {forecast.drivers.length > 0 ? (
                        <DriversTable />
                    ) : (
                        <Typography.Text type={"secondary"}>
                            {
                                "You don’t have any drivers, add one by clicking below."
                            }
                        </Typography.Text>
                    )}
                </Col>
                <Col>
                    <DriverModalButton />
                </Col>
                {forecast.drivers.length > 0 && forecast.baseline.length > 0 && (
                    <Col>
                        <Button
                            shape={"round"}
                            type={"primary"}
                            onClick={onClick}
                            loading={toggleCreateBaseline || toggleApplyDriver}
                        >
                            Apply <ApiOutlined />
                        </Button>
                    </Col>
                )}
            </Row>
        </>
    );
}
