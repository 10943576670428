import {
    DeleteOutlined,
    LineChartOutlined,
    PlusCircleTwoTone,
} from "@ant-design/icons";
import { Button, Col, DatePicker, Grid, Row, Spin, Typography } from "antd";
import moment from "moment";
import React, { Dispatch, ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { WranglrDarkBlueTitle } from "../HomeDashboardDesktop";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { Context, State } from "../../State/store";
import { updateForecast } from "../../Api/backend";
import { Action, ToggleCreateForecastBaseline } from "../../State/actions";
import useRequest from "@ahooksjs/use-request";
import cloneDeep from "lodash/cloneDeep";
import { extendMoment } from "moment-range";
import { WEEK_AND_YEAR_FORMAT } from "Utils/date-utils";

extendMoment(moment as any);

const { useBreakpoint } = Grid;

export interface WeekPeriod {
    id: string;
    fromWeek: string;
    toWeek: string;
}

interface WeekRangePickerRowProps {
    defaultOpen?: boolean;
    onChangeDate;
    onDeleteWeekPeriod;
    id: string;
    value?: [moment.Moment, moment.Moment];
}

const disabledDate = (current) => {
    return (
        current &&
        (current > moment().endOf("day") || current < moment("2018-01-01"))
    );
};

const WeekRangePickerRow = ({
    onChangeDate,
    onDeleteWeekPeriod,
    id,
    defaultOpen,
    value,
}: WeekRangePickerRowProps) => {
    const [open, setOpen] = useState(defaultOpen !== undefined ? defaultOpen : true);
    return (
        <Row>
            <Col span={21}>
                <DatePicker.RangePicker
                    onChange={onChangeDate(id)}
                    open={open}
                    onOpenChange={(open) => setOpen(open)}
                    picker="week"
                    style={{ width: "100%" }}
                    allowClear={false}
                    format={(date: moment.Moment) =>
                        date.endOf("isoWeek").format(WEEK_AND_YEAR_FORMAT)
                    }
                    disabledDate={disabledDate}
                    value={value}
                />
            </Col>
            <Col span={3}>
                <Button type={"ghost"} block={true} onClick={onDeleteWeekPeriod(id)}>
                    <DeleteOutlined />
                </Button>
            </Col>
        </Row>
    );
};

export default function WeekPeriodSelectorManager(): ReactElement {
    const [state, dispatch]: [State, Dispatch<Action>] = React.useContext(Context);
    const { toggleCreateBaseline, toggleApplyDriver } = state.forecastPage!;
    const { xxl } = useBreakpoint();
    const [numberOfCols, setNumberOfCols] = useState(xxl ? 3 : 2);
    const { forecast, setCurrentForecast } = useCurrentForecast();
    const { run: runUpdateForecast, loading } = useRequest(updateForecast, {
        manual: true,
        onSuccess: setCurrentForecast,
    });

    // Once Context Store is optimized, remove this, and store new forecast baseline directly to it.
    const [newWeekPeriodIds, setNewWeekPeriodIds] = useState<string[]>([]);

    const onChangeDate =
        (id: string) => async (dates: [moment.Moment, moment.Moment]) => {
            const [updatedFromWeek, updatedToWeek] = dates;
            const clonedBaselines = cloneDeep(forecast.baseline);

            const forecastBaselineIndex = clonedBaselines.findIndex(
                ({ id: baselineId }) => baselineId === id
            );

            const weekPeriod: WeekPeriod = {
                id,
                fromWeek: updatedFromWeek
                    .clone()
                    .startOf("day")
                    .subtract(6, "days")
                    .format("YYYY-MM-DD"),
                toWeek: updatedToWeek.clone().startOf("day").format("YYYY-MM-DD"),
            };

            if (forecastBaselineIndex === -1) {
                await runUpdateForecast({
                    ...forecast,
                    baseline: [...clonedBaselines, weekPeriod],
                });
                return setNewWeekPeriodIds(
                    newWeekPeriodIds.filter((newId) => newId !== id)
                );
            }

            clonedBaselines[forecastBaselineIndex] = weekPeriod;

            await runUpdateForecast({
                ...forecast,
                baseline: clonedBaselines,
            });
        };

    const isNewWeekPeriod = (id: string) => newWeekPeriodIds.includes(id);

    const onAddWeekRange = () =>
        setNewWeekPeriodIds([...newWeekPeriodIds, uuidv4()]);

    const onDeleteWeekPeriod = (id) => () => {
        if (isNewWeekPeriod(id)) {
            return setNewWeekPeriodIds(
                newWeekPeriodIds.filter((newId) => newId !== id)
            );
        }

        runUpdateForecast({
            ...forecast,
            baseline: forecast.baseline.filter(
                ({ id: forecastBaselineId }) => forecastBaselineId !== id
            ),
        });
    };
    // Using memoization to reduce load when calculating if there is an overlap
    const areWeekPeriodsOverlapping = React.useMemo(() => {
        for (let i = 0; i < forecast.baseline.length; i++) {
            const currentWeekPeriod = forecast.baseline[i];

            const baseRange = (moment as any).range(
                moment(currentWeekPeriod.fromWeek),
                moment(currentWeekPeriod.toWeek)
            );

            for (let j = i + 1; j < forecast.baseline.length; j++) {
                const targetRange = (moment as any).range(
                    moment(forecast.baseline[j].fromWeek),
                    moment(forecast.baseline[j].toWeek)
                );

                if (baseRange.overlaps(targetRange)) {
                    return true;
                }
            }
        }

        return false;
    }, [forecast.baseline]);

    useEffect(() => {
        setNumberOfCols(xxl ? 3 : 2);
    }, [xxl]);

    const colSpan = 24 / numberOfCols;

    return (
        <Spin spinning={loading} size={"small"}>
            <WranglrDarkBlueTitle level={4}>
                Select historical periods for your base forecast
            </WranglrDarkBlueTitle>
            <Row gutter={[20, 20]} align={"middle"}>
                {forecast.baseline.map(({ fromWeek, toWeek, id }) => {
                    return (
                        <Col span={colSpan} key={id}>
                            <WeekRangePickerRow
                                onChangeDate={onChangeDate}
                                onDeleteWeekPeriod={onDeleteWeekPeriod}
                                id={id}
                                defaultOpen={false}
                                value={[moment(fromWeek), moment(toWeek)]}
                            />
                        </Col>
                    );
                })}
                {newWeekPeriodIds.map((id) => (
                    <Col span={colSpan} key={id}>
                        <WeekRangePickerRow
                            onChangeDate={onChangeDate}
                            onDeleteWeekPeriod={onDeleteWeekPeriod}
                            id={id}
                            defaultOpen={true}
                        />
                    </Col>
                ))}

                <Col span={colSpan}>
                    <Row>
                        <Col span={24}>
                            <Button type={"ghost"} block onClick={onAddWeekRange}>
                                Add Week Range
                                <PlusCircleTwoTone />
                            </Button>
                        </Col>
                    </Row>
                </Col>
                {forecast.baseline.length > 0 && (
                    <Col span={24}>
                        <Button
                            shape={"round"}
                            type={"primary"}
                            onClick={() => {
                                const action: ToggleCreateForecastBaseline = {
                                    type: "TOGGLE_CREATE_FORECAST_BASELINE",
                                };
                                dispatch(action);
                            }}
                            loading={toggleCreateBaseline || toggleApplyDriver}
                        >
                            Create Forecast <LineChartOutlined />
                        </Button>
                    </Col>
                )}
                {areWeekPeriodsOverlapping && (
                    <Col span={24}>
                        <Typography.Text type={"warning"}>
                            Week ranges overlap detected
                        </Typography.Text>
                    </Col>
                )}
            </Row>
        </Spin>
    );
}
