import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Context } from "../../State/store";
import { getGroupData, GroupData } from "../../Api/backend";
import jwtDecode from "jwt-decode";
import LandingPage from "./LandingPage";
import ForecastCreation from "./ForecastCreation";
import { useParams } from "react-router-dom";
import { WENT_TO_FORECASTING_PAGE } from "usage-events";
import { Col, Row, Skeleton, Spin } from "antd";
import { RoundedCard } from "Components/HomeDashboardDesktop";
import CustomLoader from "Components/CustomLoader";

export interface ForecastPageParams {
    id: string;
}

export default function ForecastingDashboardDesktop(): ReactElement {
    const [loading, setLoading] = useState<boolean>(true);
    const [, dispatch] = useContext(Context);

    const { id } = useParams<ForecastPageParams>();

    useEffect(() => {
        setLoading(true);
        getGroupData().then((groupData: GroupData) => {
            const datasetName = (jwtDecode(groupData.cubejsToken) as any).user
                .dataset_name;
            const addGroupDataPayload = {
                groupData,
            };

            dispatch({ type: "ADD_GROUP_DATA", payload: addGroupDataPayload });
            dispatch({
                type: "ADD_DATASET_NAME_TO_FILTER",
                payload: { datasetName },
            });

            setLoading(false);
        });
    }, [dispatch, id]);

    if (loading) {
        return (
            <Spin indicator={<CustomLoader />}>
                <Row gutter={[8, 20]}>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                    <Col span={24}>
                        <RoundedCard>
                            <Skeleton active={true} />
                        </RoundedCard>
                    </Col>
                </Row>
            </Spin>
        );
    }

    if (id) {
        return <ForecastCreation />;
    }

    return <LandingPage />;
}
