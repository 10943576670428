import {
    Context,
    ForecastConfiguration,
    ForecastDriver,
    State,
    ValueType,
} from "../../State/store";
import React, { Dispatch, ReactElement, useEffect, useMemo, useState } from "react";
import { GroupData, updateForecast } from "../../Api/backend";
import {
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Switch,
    Typography,
} from "antd";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import useCurrentForecast from "../../Hooks/useCurrentForecast";
import { Action } from "../../State/actions";
import { DisabledTimes, EventValue } from "rc-picker/lib/interface";
import { range } from "lodash";
import InfoTooltip from "../InfoTooltip";
import {
    DRIVER_MODAL_AREAS_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_CLASSES_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_NAME_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_OVERRIDE_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_SPREAD_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_TIME_PERIOD_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_VALUE_FIELD_TOOLTIP_TEXT,
    DRIVER_MODAL_VALUE_TYPE_FIELD_TOOLTIP_TEXT,
} from "../ForecastingDashboardDesktop/constants";
import { DATE_WITH_HOURS_AND_MINUTES_FORMAT } from "Utils/date-utils";
import { usePreviewMode } from "Store/viewSettingsStore";
import { useListMappingRules } from "Hooks/mappingConfigurator";
import { BaselineMappedRow } from "Pages/Forecast/Components/ForecastChart";
import { filterBaselineDataIfRequired } from "Components/ForecastingChartsDataManager";
import { groupDataByField } from "Components/ForecastingChartsDataManager/BaselineChart";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import { RootState } from "Redux/store";

interface DriverModalProps {
    existingDriver?: ForecastDriver;
    visible: boolean;
    setVisible;
    cloneDriver?: boolean;
}

export interface SelectOption {
    key: string;
    value: string;
}

interface DriverModalFormLabelProps {
    label: string;
    tooltipText: string | ReactElement;
}

const DriverModalFormLabel = ({ label, tooltipText }: DriverModalFormLabelProps) => (
    <Typography.Text>
        {label} <InfoTooltip tooltipText={tooltipText} />
    </Typography.Text>
);

const disableDatesNotInForecastConfig =
    ({ weekPeriod }: ForecastConfiguration) =>
    (current: moment.Moment) => {
        const endOfWeek = moment(weekPeriod)
            .clone()
            .add("1", "week")
            .startOf("isoWeek")
            .format("YYYY-MM-DD");

        return !current.isBetween(weekPeriod, endOfWeek, "day", "[]");
    };

const disableTimesNotInForecastConfig =
    ({ weekPeriod }: ForecastConfiguration) =>
    (current: EventValue<moment.Moment>): DisabledTimes => {
        if (!current) {
            return {};
        }
        const lastDay = moment(weekPeriod)
            .clone()
            .add("1", "week")
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
        if ((current as moment.Moment).format("YYYY-MM-DD") === lastDay) {
            return {
                disabledHours: () => range(6, 24),
            };
        }
        return {};
    };

interface ValueInputFieldProps {
    valueType: ValueType | undefined;
}

const ValueInputField = (props: ValueInputFieldProps): ReactElement => {
    const { valueType } = props;
    if (valueType === "Flat") {
        return <Input addonBefore={"$"} {...props} />;
    } else if (valueType === "Percentage") {
        return <Input addonAfter={"%"} {...props} />;
    }
    return <Input {...props} />;
};

type ValueTypeLabel = "Dollar-based" | "Percentage";

/**
 * Helper function that converts the selected value of "Dollar-based"
 * to "Flat" to avoid breaking API calls.
 *
 * @param selectedValue {"Dollar-based" | "Percentage"}
 * @returns "Flat" OR "Percentage"
 */
const valueTypeConversionHelper = (
    selectedValue: ValueTypeLabel | ValueType
): ValueType => (selectedValue === "Dollar-based" ? "Flat" : selectedValue);

/**
 * Similar to the above, but reversed to return the label from the valueType
 * @param selectedValue
 */
const valueTypeLabelConversionHelper = (
    selectedValue: ValueTypeLabel | ValueType
): ValueTypeLabel => (selectedValue === "Flat" ? "Dollar-based" : selectedValue);

export const DriverModal = ({
    existingDriver,
    visible,
    setVisible,
    cloneDriver,
}: DriverModalProps): ReactElement => {
    const baselineData = useAppSelector(
        (state: RootState) => state.currentForecastSlice.baseline
    );

    const [state]: [State, Dispatch<Action>] = React.useContext(Context);
    const { forecast, setCurrentForecast } = useCurrentForecast();
    const { segments }: GroupData = state.groupData!;
    const { classes } = segments;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [overrideChecked, setOverrideChecked] = useState(existingDriver?.override);
    const [valueType, setValueType] = useState<
        ValueType | ValueTypeLabel | undefined
    >(undefined);
    const { previewMode } = usePreviewMode();
    const { data } = useListMappingRules();
    const [form] = Form.useForm<ForecastDriver>();
    const [areaOptions, setAreaOptions] = useState<
        {
            key: string;
            value: string;
        }[]
    >([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        let areas: BaselineMappedRow[] = [];
        if (baselineData === undefined || !baselineData.length) return;
        baselineData
            ?.filter(
                filterBaselineDataIfRequired(
                    {
                        days: [],
                        areas: [],
                        classes: [],
                    },
                    false,
                    true,
                    true
                )
            )
            ?.reduce(groupDataByField(areas, "mapped_area"), {});
        areas = areas?.filter((row: BaselineMappedRow) => row.x !== "");
        const localAreaOptions = areas?.map((currentArea) => {
            const currentMatch = data?.find((currentSegment) => {
                return currentArea.x === currentSegment.segmentName;
            });
            if (currentMatch === undefined)
                throw Error("This shouldn't be possible");

            return { key: currentMatch?.id, value: currentMatch?.segmentName };
        });
        setAreaOptions(localAreaOptions);
        setIsLoading(false);
    }, [baselineData]);

    const classOptions = useMemo(() => {
        if (previewMode && data) {
            return data
                .filter(({ segment }) => segment === "class")
                .map(({ segmentName, id }) => ({ key: id, value: segmentName }));
        }

        return classes.map((c) => ({ key: c.primary_id, value: c.primary_id }));
    }, [classes, data, previewMode]);

    useEffect(() => {
        if (existingDriver && visible) {
            setOverrideChecked(existingDriver.override);
            setValueType(valueTypeLabelConversionHelper(existingDriver.valueType));
            form.setFields([
                { name: "name", value: existingDriver.name },
                { name: "areas", value: existingDriver.areas },
                { name: "classes", value: existingDriver.classes },
                {
                    name: "valueType",
                    value: valueTypeLabelConversionHelper(existingDriver.valueType),
                },
                { name: "value", value: existingDriver.value },
                { name: "spread", value: existingDriver.spread },
                {
                    name: "timePeriod",
                    value: [
                        moment(existingDriver.timePeriod.fromDate),
                        moment(existingDriver.timePeriod.toDate),
                    ],
                },
                {
                    name: "override",
                    value: existingDriver.override,
                },
            ]);
        } else {
            const defaultStartTime = "T06:00:00";
            const defaultEndTime = "T05:45:00";
            const modifiedWeek = moment(
                moment(forecast.configuration.weekPeriod).format("YYYY-MM-DD") +
                    defaultStartTime
            );
            const modifiedEndOfWeek = moment(
                moment(forecast.configuration.weekPeriod)
                    .add(1, "week")
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD") + defaultEndTime
            );
            form.setFields([
                {
                    name: "timePeriod",
                    value: [modifiedWeek, modifiedEndOfWeek],
                },
            ]);
        }
    }, [visible]);

    const onCancel = () => {
        if (!confirmLoading) {
            setVisible(false);
        }
    };

    const onOk = () => {
        form.validateFields()
            .then((values) => {
                setConfirmLoading(true);
                const parsedValue = Number(String(values.value).replace(",", ""));
                const driver: ForecastDriver = {
                    ...values,
                    valueType: valueTypeConversionHelper(valueType!),
                    value: parsedValue,
                    areas: values.areas ? values.areas : [],
                    classes: values.classes ? values.classes : [],
                    override: values.override ? values.override : false,
                    id: uuidv4(),
                    timePeriod: {
                        fromDate: values.timePeriod[0].format(
                            DATE_WITH_HOURS_AND_MINUTES_FORMAT
                        ),
                        toDate: values.timePeriod[1].format(
                            DATE_WITH_HOURS_AND_MINUTES_FORMAT
                        ),
                    },
                };

                let newDriversArr: ForecastDriver[] = [...forecast.drivers];
                if (existingDriver && !cloneDriver) {
                    const i = forecast.drivers.findIndex(
                        (driver) => driver.id === existingDriver.id
                    );
                    newDriversArr[i] = driver;
                } else {
                    newDriversArr = newDriversArr.concat(driver);
                }
                const currentForecast = {
                    ...forecast,
                    drivers: newDriversArr,
                };

                updateForecast(currentForecast).then((forecast) => {
                    setCurrentForecast(forecast);
                    form.resetFields();
                    setConfirmLoading(false);
                    setVisible(false);
                });
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <Modal
            title={"Create Forecast Driver"}
            onOk={onOk}
            onCancel={onCancel}
            visible={visible}
            width={750}
            confirmLoading={confirmLoading}
        >
            {isLoading ? (
                <QuantacoLoader size={40} />
            ) : (
                <Form form={form} layout="vertical">
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name={"name"}
                                label={
                                    <DriverModalFormLabel
                                        label={"Name"}
                                        tooltipText={
                                            DRIVER_MODAL_NAME_FIELD_TOOLTIP_TEXT
                                        }
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        max: 20,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name={"timePeriod"}
                                label={
                                    <DriverModalFormLabel
                                        label={"Time Period"}
                                        tooltipText={
                                            DRIVER_MODAL_TIME_PERIOD_FIELD_TOOLTIP_TEXT
                                        }
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a time period",
                                    },
                                ]}
                            >
                                <DatePicker.RangePicker
                                    onPanelChange={undefined}
                                    picker={"date"}
                                    format={"YYYY-MM-DD HH:mm"}
                                    disabledDate={disableDatesNotInForecastConfig(
                                        forecast.configuration
                                    )}
                                    disabledTime={disableTimesNotInForecastConfig(
                                        forecast.configuration
                                    )}
                                    mode={["date", "date"]}
                                    showTime={{
                                        minuteStep: 15,
                                        showSecond: false,
                                        format: "HH:mm",
                                        hideDisabledOptions: true,
                                    }}
                                    style={{ width: "100%" }}
                                    onOpenChange={(open) => {
                                        if (open) return;
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"areas"}
                                label={
                                    <DriverModalFormLabel
                                        label={"Areas"}
                                        tooltipText={
                                            DRIVER_MODAL_AREAS_FIELD_TOOLTIP_TEXT
                                        }
                                    />
                                }
                            >
                                <Select
                                    defaultValue={[]}
                                    style={{ width: "100%" }}
                                    options={areaOptions}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount={2}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"classes"}
                                label={
                                    <DriverModalFormLabel
                                        label={"Classes"}
                                        tooltipText={
                                            DRIVER_MODAL_CLASSES_FIELD_TOOLTIP_TEXT
                                        }
                                    />
                                }
                            >
                                <Select
                                    defaultValue={[]}
                                    style={{ width: "100%" }}
                                    options={classOptions}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount={2}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"valueType"}
                                label={
                                    <DriverModalFormLabel
                                        label={"Value Type"}
                                        tooltipText={
                                            DRIVER_MODAL_VALUE_TYPE_FIELD_TOOLTIP_TEXT
                                        }
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: "Invalid selection",
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    options={[
                                        {
                                            key: "Flat",
                                            value: "Dollar-based",
                                        },
                                        {
                                            key: "Percentage",
                                            value: "Percentage",
                                        },
                                    ]}
                                    allowClear
                                    onChange={(val) =>
                                        setValueType(
                                            valueTypeConversionHelper(
                                                val as ValueTypeLabel
                                            )
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={"value"}
                                label={
                                    <DriverModalFormLabel
                                        label={"Value"}
                                        tooltipText={
                                            DRIVER_MODAL_VALUE_FIELD_TOOLTIP_TEXT
                                        }
                                    />
                                }
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Cannot have empty value or have non-number characters",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            const parsedValue = Number(value);
                                            if (Number.isNaN(parsedValue)) {
                                                return Promise.reject(
                                                    "Please enter a valid number"
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <ValueInputField
                                    valueType={valueTypeConversionHelper(
                                        valueType as ValueTypeLabel
                                    )}
                                />
                            </Form.Item>
                        </Col>
                        {/* These options are only applicable for even value types*/}
                        {valueType &&
                            valueTypeConversionHelper(valueType) === "Flat" && (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"spread"}
                                            label={
                                                <DriverModalFormLabel
                                                    label={"Spread"}
                                                    tooltipText={
                                                        DRIVER_MODAL_SPREAD_FIELD_TOOLTIP_TEXT
                                                    }
                                                />
                                            }
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Invalid selection",
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: "100%" }}
                                                options={[
                                                    {
                                                        key: "Even",
                                                        value: "Even",
                                                    },
                                                    {
                                                        key: "Proportional",
                                                        value: "Proportional",
                                                    },
                                                ]}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={"override"}
                                            label={
                                                <DriverModalFormLabel
                                                    label={"Override"}
                                                    tooltipText={
                                                        DRIVER_MODAL_OVERRIDE_FIELD_TOOLTIP_TEXT
                                                    }
                                                />
                                            }
                                        >
                                            <Switch
                                                checked={overrideChecked}
                                                onChange={(checked) =>
                                                    setOverrideChecked(checked)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                    </Row>
                </Form>
            )}
        </Modal>
    );
};
