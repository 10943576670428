import {
    createMappingRule,
    deleteMappingRule,
    getMappingRuleById,
    IntegrationSource,
    listIntegrationSources,
    listMappingRules,
    listSegmentsByIntegrationSource,
    MappingRule,
    Segment,
    updateMappingRule,
} from "Api/mappingConfigurator";
import { chain } from "lodash";
import { useMemo } from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

type MappingRuleID = string;

export const useGetMappingRuleById = (
    id: string
): UseQueryResult<MappingRule, any> => {
    const queryKey = ["MAPPING_RULE", id];
    return useQuery(queryKey, () => getMappingRuleById(id), {
        staleTime: Infinity,
        retry: false,
    });
};

export const useListSegmentsByIntegrationSource = (
    integrationSource?: string
): UseQueryResult<Segment[], any> => {
    const queryResult = useQuery(
        ["SEGMENTS", integrationSource],
        () => listSegmentsByIntegrationSource(integrationSource),
        {
            staleTime: Infinity,
            enabled: Boolean(integrationSource),
            select: (result) => {
                return result.map(
                    ({
                        segment_id: segmentId,
                        segment_type: segmentType,
                        segment_value: segmentName,
                        integration_name: integrationName,
                        parent_segment_id: parentSegmentId,
                    }) => ({
                        segmentId,
                        segmentType,
                        segmentName,
                        integrationName,
                        parentSegmentId,
                    })
                );
            },
        }
    );
    return queryResult;
};

export const useListIntegrationSources = (): UseQueryResult<
    IntegrationSource[],
    any
> => {
    const queryResult = useQuery("INTEGRATION_SERVICES", listIntegrationSources, {
        staleTime: Infinity,
        select: (result) => {
            return result.map(
                ({
                    integration_id: integrationId,
                    integration_name: integrationName,
                    services,
                }) => ({
                    integrationId,
                    integrationName,
                    services: services,
                })
            );
        },
    });

    return queryResult;
};

export const useListMappingRules = (): {
    data?: MappingRule[];
    isLoading: boolean;
    refetch: () => void;
    mappingRuleById: Record<string, MappingRule>;
    mappingRuleBySegmentName: Record<string, MappingRule>;
} => {
    const { data, isLoading, refetch } = useQuery(
        "MAPPING_RULES",
        listMappingRules,
        {
            select: (result) =>
                result
                    .filter(({ result }) => Boolean(result))
                    .map((mappingRule) => {
                        if (mappingRule.segment !== "class") return mappingRule;

                        return {
                            ...mappingRule,
                            result: mappingRule.result.map((mappingRuleResult) => {
                                // deputy doesn't have class name
                                return ["deputy", "tanda", "keypay"].includes(
                                    mappingRuleResult.integrationSource
                                )
                                    ? {
                                          ...mappingRuleResult,
                                          areaIds: undefined,
                                          classIds: mappingRuleResult.areaIds,
                                      }
                                    : mappingRuleResult;
                            }),
                        };
                    }),
        }
    );

    const mappingRuleById = useMemo(
        () => chain(data).keyBy("id").mapValues().value(),
        [data]
    );

    const mappingRuleBySegmentName = useMemo(
        () => chain(data).keyBy("segmentName").mapValues().value(),
        [data]
    );

    return { isLoading, data, refetch, mappingRuleById, mappingRuleBySegmentName };
};

export const useCreateMappingRule = (): UseMutationResult<
    void,
    any,
    MappingRule
> => {
    const queryClient = useQueryClient();

    return useMutation<void, any, MappingRule>(createMappingRule, {
        onSettled: (data: any, err: any, mappingRule: MappingRule) => {
            queryClient.setQueryData(["MAPPING_RULE", mappingRule.id], mappingRule);
        },
    });
};

export const useDeleteMappingRule = (): UseMutationResult<
    void,
    any,
    MappingRuleID
> => {
    const queryClient = useQueryClient();

    return useMutation<void, any, MappingRuleID>(deleteMappingRule, {
        onSettled: (data: any, err: any, mappingRuleID: string) => {
            queryClient.setQueryData(["MAPPING_RULE", mappingRuleID], mappingRuleID);
        },
    });
};

export const useUpdateMappingRule = (): UseMutationResult<
    void,
    any,
    MappingRule
> => {
    const queryClient = useQueryClient();

    return useMutation<void, any, MappingRule>(updateMappingRule, {
        onSettled: (data: any, err: any, mappingRule: MappingRule) => {
            queryClient.setQueryData(["MAPPING_RULE", mappingRule.id], mappingRule);
        },
    });
};
